@charset "utf-8";@font-face{font-family:'Acronym';src:url('Acronym-Light.woff2') format('woff2'),
	url('Acronym-Light.woff') format('woff');font-weight:300;font-style:normal;font-display: swap;}
	@font-face{font-family:'Acronym';src:url('Acronym-Bold.woff2') format('woff2'),
	url('Acronym-Bold.woff') format('woff');font-weight:bold;font-style:normal;font-display: swap;}
	@font-face{font-family:'Acronym';src:url('Acronym-Regular.woff2') format('woff2'),
	url('Acronym-Regular.woff') format('woff');font-weight:normal;font-style:normal;font-display: swap;}
	@font-face{font-family:'Acronym';src:url('Acronym-Black.woff2') format('woff2'),
	url('Acronym-Black.woff') format('woff');font-weight:900;font-style:normal;font-display: swap;}
	@font-face{font-family:'Acronym';src:url('Acronym-Semibold.woff2') format('woff2'),
	url('Acronym-Semibold.woff') format('woff');font-weight:600;font-style:normal;font-display: swap;}
	.hide-desk, .show-479, .show-tab, .show-767, .show-400, .show-340, .show-359, .tab-video, .bnricon-mob{display:none;}
	.hide-400, .hide-479{display:inline-block;}
	@media only screen and (max-width:1179px){.show-desk{display:block;}
	.show-hide{display:none;}
	}
	@media only screen and (max-width:1013px){.show-tab{display:block;}
	.hide-tab{display:none;}
	.tab-video{display:block;}
	}
	@media only screen and (max-width:767px){.hide-767, .hide-desk{display:none;}
	.show-767{display:block;}
	.bnricon-mob{display:inline-block;}
	}
	@media only screen and (max-width:570px){.hide-570{display:none;}
	}
	@media only screen and (max-width:479px){.show-479{display:block;}
	.hide-479, .tab-video{display:none;}
	}
	@media only screen and (max-width:400px){.show-400{display:block;}
	.hide-400{display:none;}
	}
	@media only screen and (max-width:359px){.show-359{display:block;}
	.hide-359{display:none;}
	}
	@media only screen and (max-width:340px){.show-340{display:block;}
	}
	footer{padding:40px 0;border-top:1px solid #d2d2d2;}
	ul.ftrlist{text-align:center;}
	ul.ftrlist li{display:inline-block;border-right:1px solid #343434;padding:0 7px 0 2px;font-size:18px;line-height:16px;color:#343434;text-align:center;font-weight:300;margin:0;}
	ul.ftrlist li:last-child{border-right:none;}
	ul.ftrlist li a{color:#343434;}
	.ftrtxt{font-size:18px;line-height:16px;color:#343434;font-weight:300;text-align:center;padding:15px 0 0 0;}
	@media only screen and (max-width:767px){footer{padding:0 0 25px 0;}
	ul.ftrlist{padding:16px 0 0 0;}
	ul.ftrlist li, .ftrtxt{font-size:16px;line-height:16px;}
	.ftrtxt{padding:6px 0 0 0;}
	#ctabtn-mob{padding:10px 0 8px 0;background:#16556a;position:fixed;bottom:0px;left:auto;transition:bottom 1s;display:table;width:100%;z-index:999;}
	.ctabtn{max-width:96%;margin:0 auto;display:block;}
	}
	@media only screen and (max-width:479px){ul.ftrlist{width:330px;margin:0 auto;display:block;padding:12px 0 0 0;}
	ul.ftrlist li, .ftrtxt{font-size:13px;line-height:13px;}
	ul.ftrlist li{padding:0 4px 0 1px;}
	}
	@media only screen and (max-width:359px){ul.ftrlist{width:auto;}
	}